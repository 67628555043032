@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'sass:color';

.balance {
  @include variables.label;
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: variables.$color-slate;

  @media (max-width: variables.$device-md) {
    right: 12px;
    bottom: -24px;
    left: unset;
  }
}

.max-button {
  margin-top: 8px;
}

.select-token {
  display: flex;
  gap: 4px;
  align-items: center;
  border: none;
  border-bottom: 1px solid rgba(variables.$color-purple, 0.25);
  padding: 12px 4px 8px 0;
  background: none;
  cursor: pointer;
  transition: variables.$transition-s;

  svg {
    margin-left: 4px;
    stroke: variables.$color-purple;
    transition: variables.$transition-s;
  }

  &:hover {
    border-bottom-color: variables.$color-purple;
  }

  &--active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.token-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;

  & > li {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    border-radius: 0;
    padding: 8px 12px;
    text-align: start;
    list-style: none;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(variables.$color-purple, 0.1);
    }

    &:hover {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(variables.$color-purple, 0.1);
      }

      span {
        font-weight: 500;
      }
    }

    span {
      @include variables.paragraph;
      display: flex;
      gap: 10px;
    }
  }
}

.token-loader {
  margin-right: auto;
  margin-left: auto;
}

.options-search {
  position: sticky;
  top: 0;
  z-index: 101;
  border-radius: 12px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  div {
    height: fit-content;
    min-height: unset;
    border: none;
    border-bottom: 1px solid variables.$color-lighter;
    border-radius: 12px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  input {
    @include utils.increase-specificity(2) {
      @include variables.paragraph;
      z-index: 102;
      height: fit-content;
      border-radius: 12px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: 8px 12px;
      background-color: variables.$color-near-white;
    }
  }
}

.options-floater {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 20rem;
  max-height: 240px;
  border-radius: 12px;
  padding: 0;
  padding-bottom: 12px;
  overflow: auto;
}

input.input-inner {
  padding-top: 16px;
  padding-bottom: 24px;
}
