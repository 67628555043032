@use 'libs/styles/variables';
@use 'libs/styles/utils';
@use 'libs/styles/layout';

.upgrade-section {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 4rem;
  border-bottom: 1px solid variables.$color-lighter;
  padding-bottom: 6rem;
  background: linear-gradient(180deg, transparent 0%, white 100%),
    linear-gradient(
      270deg,
      rgba(173, 122, 255, 13%) 6.24%,
      rgba(207, 117, 203, 13%) 47.74%,
      rgba(252, 113, 138, 13%) 65.24%,
      rgba(248, 126, 38, 13%) 88.24%,
      rgba(252, 137, 53, 13%) 100.24%
    );

  &::before {
    @include layout.absolute-box;
    height: 1px;
    background: linear-gradient(270deg, #53acde 0%, #a499ff 43.33%, #ff7f7f 56.76%, #ffaa6b 92.16%, #ffbc58 100%);
  }

  @media (max-width: variables.$device-sm) {
    margin-bottom: 2rem;
    padding: 0 1rem 3rem;
  }

  h2 {
    margin-bottom: 0.5rem;
    padding-top: 5.5rem;
    font-size: 2.5rem;
    font-family: 'GT Haptik', sans-serif;
    text-align: center;
    color: rgba(variables.$color-dark-light, 0.95);

    @media (max-width: variables.$device-sm) {
      padding-top: 3.5rem;
      font-size: 1.5rem;
    }

    b {
      background: linear-gradient(
        90deg,
        #d59070 29.05%,
        #d58277 40.88%,
        #d5737d 52.7%,
        #9682db 64.53%,
        #798add 76.35%,
        #618fd3 88.18%,
        #4d94cb 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__content {
    button {
      @include utils.increase-specificity {
        width: fit-content;
        max-width: 34rem;
      }
    }
  }

  &__button {
    max-width: 34rem;
  }

  &__decoration {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 1;
      width: 20rem;
      pointer-events: none;

      &:first-of-type {
        transform: translate(calc(-50% - 26rem), calc(-50% - 2rem)) rotate(5deg);
      }

      &:last-of-type {
        width: 18rem;
        transform: translate(calc(-50% + 28rem), calc(-50% + 6rem)) rotate(5deg);
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 50%;
      width: 32rem;
      border: 1px solid variables.$color-purple;
      border-radius: 1rem;
      box-shadow: variables.$box-shadow-medium-purple;
      transition: variables.$transition-m;

      &:first-of-type {
        transform: translate(calc(-50% - 40rem), calc(-50% - 2rem)) rotate(-7deg);

        &:hover {
          transform: translate(calc(-50% - 40rem), calc(-50% - 3rem)) rotate(-5deg) scale(1.05);
        }
      }

      &:last-of-type {
        border-color: variables.$color-fund;
        box-shadow: variables.$box-shadow-medium-orange;
        transform: translate(calc(-50% + 40rem), calc(-50% - 8rem)) rotate(7deg);

        &:hover {
          transform: translate(calc(-50% + 40rem), calc(-50% - 9rem)) rotate(5deg) scale(1.05);
        }
      }
    }
  }
}
