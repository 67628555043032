@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'libs/styles/components';
@use 'sass:color';

.hero {
  @include layout.column(0);
  position: relative;
  justify-content: center;
  align-items: center;

  @media (max-width: variables.$device-sm) {
    height: auto;
    min-height: unset;
    max-height: unset;
  }

  * {
    position: relative;
    z-index: 1;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    padding-top: 0;
    padding-left: 1rem;
    font-size: 4.5rem;
    line-height: 0.9;
    letter-spacing: -0.05rem;
    text-align: left;
    color: rgba(variables.$color-dark-light, 0.95);

    b {
      background: linear-gradient(
        90deg,
        #d59070 29.05%,
        #d58277 40.88%,
        #d5737d 52.7%,
        #9682db 64.53%,
        #798add 76.35%,
        #618fd3 88.18%,
        #4d94cb 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media (max-width: variables.$device-md) {
      font-size: 3rem;
    }

    @media (max-width: variables.$device-sm) {
      margin-top: 2rem;
      margin-bottom: 1rem;
      padding-left: 0;
      font-size: 2.25rem;
    }
  }
}

.hero-top {
  @include variables.rainbow-gradient-background($position: 'bottom', $flip: true);
  width: 100%;

  &::after {
    bottom: 1px;
  }

  @media (max-width: variables.$device-sm) {
    margin-bottom: 4rem;
  }
}

.hero-inner {
  @include layout.max-width;
  @include layout.row(0);
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: variables.$device-sm) {
    width: 100%;
    padding: 0 1rem;
  }
}

.text {
  @include layout.column(0);
  align-items: flex-start;
  margin-top: auto;

  @media (max-width: variables.$device-sm) {
    align-items: stretch;
    width: 100%;
  }

  & > svg {
    width: 10rem;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    color: variables.$color-purple;
  }

  & > h4 {
    padding-left: 1.25rem;
    font-size: 1.325rem;
    text-align: left;
    color: variables.$color-disabled;

    @media (max-width: variables.$device-md) {
      font-size: 1.25rem;
    }

    @media (max-width: variables.$device-sm) {
      padding-left: 0;
      font-size: 1rem;
    }
  }
}

.action-button {
  max-width: 30rem;
  margin-left: 0.5rem;
  transform: translateY(50%);

  a > div:last-child > svg {
    opacity: 1;
  }

  &:hover {
    transform: translateY(calc(50% - 0.5rem));
  }

  @media (max-width: variables.$device-sm) {
    max-width: unset;
    margin-top: 0;
    margin-left: 0;
  }
}

.character {
  display: flex;
  align-items: flex-end;
  width: 320px;
  margin-right: 7%;
  overflow: hidden;

  img {
    object-position: center bottom;
  }

  @media (max-width: variables.$device-sm) {
    display: none;
  }
}

.audiences {
  @include layout.max-width;
  @include layout.row;
  width: 100%;
  margin: 6rem 0 1.5rem;
  padding-inline: 0;
  transform: scale(0.95);
  transform-origin: center bottom;
  opacity: 0.75;

  &:hover {
    transform: scale(1);
    opacity: 1;
  }

  @media (max-width: variables.$device-sm) {
    @include layout.column(0.75rem);
    align-items: stretch;
    margin-top: 0;
    margin-bottom: 0;
  }

  & > a {
    @include layout.column(0.75rem);
    flex-basis: 33%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 3.5rem;
    padding-bottom: 0.75rem;

    @media (max-width: variables.$device-md) {
      gap: 0.5rem;
      padding: 0.5rem 2.5rem;
    }

    @media (max-width: variables.$device-sm) {
      gap: 0.5rem;
      padding: 0.5rem 1rem;
    }

    &:not(:last-child) {
      border-right: 1px solid rgba(variables.$color-purple, 0.25);

      @media (max-width: variables.$device-md) {
        border-right: none;
      }
    }

    &:first-child {
      --highlight-color: #{variables.$color-fund};
    }

    &:nth-child(2) {
      --highlight-color: #{variables.$color-org-2};
    }

    &:last-child {
      --highlight-color: #{variables.$color-org};
    }

    h3 {
      @include variables.title(3, true);
      font-weight: 700;
      font-family: 'GT Haptik', sans-serif;
      text-align: left;
      color: rgba(variables.$color-dark-light, 0.825);
      transition: variables.$transition-s;

      @media (max-width: variables.$device-md) {
        font-size: 1.75rem;
      }

      @media (max-width: variables.$device-sm) {
        font-size: 1.5rem;
      }
    }

    &:hover {
      h3 {
        color: var(--highlight-color);
      }

      > .hero__audiences-tag > svg:last-child {
        @include utils.increase-specificity(2) {
          margin-left: 0.2rem;
          opacity: 0.5;
        }
      }
    }
  }
}

.audiences-tag {
  @include layout.row(0, true);

  .audiences > a:nth-child(1) & {
    @include components.for-link(variables.$color-fund, false);
  }

  .audiences > a:nth-child(2) & {
    @include components.for-link(variables.$color-org-2, false);
  }

  .audiences > a:nth-child(3) & {
    @include components.for-link(variables.$color-org, false);
  }
}
