@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'libs/styles/components';

.section {
  @include layout.max-width;
  margin-bottom: 0;
  padding: 3.5rem 1.5rem 0;
  transform: scale(0.95) translateY(10px);
  opacity: 0.25;
  transition: variables.$transition-m;

  &:hover,
  &[data-in-view='true'] {
    transform: none;
    opacity: 1;
  }

  @media (max-width: variables.$device-sm) {
    padding: 2rem 1rem 0;
  }
}

.box {
  position: relative;
  margin: 0 1rem;
  border-radius: 2rem;
  padding: 2.5rem 3rem 3rem;
  background: linear-gradient(
    90deg,
    #ffbc58b4 0%,
    #ffaa6bb4 7.84%,
    #ff7f7fb4 43.24%,
    #e388c0b4 49.31%,
    #a499ffb4 56.67%,
    #53acdeb4 100%
  );
  box-shadow: variables.$box-shadow-light;
  transition: variables.$transition-m;

  &:hover {
    background: linear-gradient(
      90deg,
      #ffbc58 0%,
      #ffaa6b 7.84%,
      #ff7f7f 43.24%,
      #e388c0 49.31%,
      #a499ff 56.67%,
      #53acde 100%
    );
  }

  @media (max-width: variables.$device-sm) {
    margin: 0;
    border-radius: 1.5rem;
    padding: 1rem;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    @include layout.absolute-box;
    top: 1px;
    left: 1px;
    z-index: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: calc(2rem - 1px);
    background: white;

    @media (max-width: variables.$device-sm) {
      border-radius: calc(1.5rem - 1px);
    }
  }
}

.app-icon {
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
  border: 2px solid rgba(variables.$color-purple, 0.25);
  border-radius: 1.5rem;
  padding: 20px;
  color: variables.$color-purple;
  box-shadow: variables.$box-shadow-medium-purple;
  transition: variables.$transition-m;

  .box:hover & {
    border-color: rgba(variables.$color-purple, 0.5);
  }

  @media (max-width: variables.$device-sm) {
    display: none;
  }
}

.header {
  @include layout.row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;

  @media (max-width: variables.$device-sm) {
    flex-direction: column;
  }

  span {
    @include variables.paragraph(true);
    margin-top: 0.25rem;
    font-weight: 500;
    color: variables.$color-purple;

    @media (max-width: variables.$device-sm) {
      margin-left: 0.5rem;
      font-weight: 400;
      color: variables.$color-fund;
    }
  }
}

.title {
  @include components.for-link(variables.$color-fund);
}

.dots {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1;
  max-width: 48%;
  height: auto;
  border-radius: calc(2rem - 1px);
  object-position: bottom right;
  transform: scale(2);
  transform-origin: bottom right;

  @media (max-width: variables.$device-sm) {
    border-radius: 1rem;
  }

  circle {
    fill: rgba(variables.$color-org, 0.5);
    transition: variables.$transition-m;

    .box:hover & {
      fill: variables.$color-org;
    }
  }
}

.spread {
  @include layout.row(0);
  align-items: stretch;

  @media (max-width: variables.$device-md) {
    flex-direction: column-reverse;
  }
}

.details {
  @include layout.column(1rem);
  flex: 0 0 50%;
  align-items: flex-start;

  @media (max-width: variables.$device-md) {
    gap: 0.5rem;
  }

  & > div:first-child {
    @include layout.row(1.5rem);

    @media (max-width: variables.$device-sm) {
      flex-direction: column;
      gap: 0.75rem;
      align-items: flex-start;
      margin-top: 0;
      padding-right: 0.25rem;
    }

    h3 {
      @include variables.title(1, true);
      display: block;
      align-items: flex-start;
      margin: 0;
      padding: 0;
      line-height: 1.1;

      @media (max-width: variables.$device-md) {
        line-height: 0.9;
      }

      b {
        background: linear-gradient(
          90deg,
          #d59070 29.05%,
          #d58277 40.88%,
          #d5737d 52.7%,
          #9682db 64.53%,
          #798add 76.35%,
          #618fd3 88.18%,
          #4d94cb 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  & > div:nth-of-type(2) {
    @include layout.row(0.5rem);
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1rem;

    & > span {
      padding-left: 0.75rem;
      font-size: 1rem;
      font-weight: 500;

      @media (max-width: variables.$device-md) {
        border-radius: 0.75rem;
        padding: 0.5rem;
        padding-right: 0.75rem;
        font-size: 0.75rem;
      }

      svg {
        color: variables.$color-purple;

        @media (max-width: variables.$device-md) {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  & > h4 {
    padding: 0.5rem;
    color: variables.$color-disabled;

    @media (max-width: variables.$device-md) {
      margin-bottom: 1rem;
      padding: 0 0.5rem;
      font-size: 0.75rem;
    }
  }

  & > :last-child {
    @include utils.increase-specificity {
      width: 75%;

      @media (max-width: variables.$device-md) {
        width: 100%;
      }
    }
  }
}

.shots {
  position: relative;
  flex: 0 0 50%;

  @media (max-width: variables.$device-md) {
    flex: 0 0 15rem;
    margin-top: 2rem;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    border: 1px solid variables.$color-slate;
    border-radius: 1rem;
    box-shadow: variables.$box-shadow-light-purple;
    transition: variables.$transition-m;

    .box:hover & {
      border-color: variables.$color-purple;
    }

    &:first-child {
      transform: translate(calc(-50% - 3rem), calc(-50% - 2rem)) rotate(-5deg);

      .box:hover & {
        transform: translate(calc(-50% - 3rem), calc(-50% - 2.5rem)) rotate(-7deg) scale(1.05);
      }

      @media (max-width: variables.$device-md) {
        .box:hover &,
        & {
          transform: translate(calc(-50% - 2rem), calc(-50% - 3rem)) rotate(-5deg);
        }
      }
    }

    &:last-child {
      height: 75%;
      transform: translate(calc(-50% + 6rem), calc(-50% + 1rem)) rotate(5deg);

      .box:hover & {
        transform: translate(calc(-50% + 7rem), calc(-50%)) rotate(7deg) scale(1.05);
      }

      @media (max-width: variables.$device-md) {
        .box:hover &,
        & {
          transform: translate(calc(-50% + 3rem), calc(-50%)) rotate(5deg);
        }
      }
    }
  }
}

.safety {
  @include layout.row(0);
  align-items: center;
  margin-top: 3rem;
  border-top: 1px solid rgba(variables.$color-org-2, 0.25);
  padding-top: 2rem;

  @media (max-width: variables.$device-md) {
    @include layout.column(1rem);
    margin-top: 1rem;
    padding-top: 0;
  }

  img {
    pointer-events: none;
    filter: none;
  }

  & > div:first-child {
    flex: 0 0 35%;
    padding: 1.5rem;
    padding-left: 0;

    @media (max-width: variables.$device-md) {
      padding: 0 0.5rem;
      padding-top: 2rem;
    }

    h3 {
      @include variables.title(2, true);
      margin-bottom: 1rem;
      font-family: 'GT Haptik', sans-serif;
      line-height: 0.95;
    }

    p {
      @include variables.paragraph(true);
      margin-bottom: 1.5rem;
    }
  }
}
