@use 'libs/styles/variables';
@use 'libs/styles/utils';

.get-started-button {
  @include utils.increase-specificity {
    grid-template-columns: 3rem 1fr 2.5rem;
    width: 100%;
    min-width: 30rem;
    border-radius: 1.75rem;
    color: white;
    transition: variables.$transition-m;

    @media (max-width: variables.$device-sm) {
      display: flex;
      min-width: unset;
      border-radius: 1rem;
      padding: 0.75rem 1rem;
      padding-left: 1.25rem;
    }

    & > div:last-child {
      svg {
        width: 100%;
        opacity: 0.675;
      }
    }

    & > div:first-child {
      box-shadow: none;
      transform: none;

      @media (max-width: variables.$device-sm) {
        display: none;
      }
    }

    & > div:last-child > svg > path {
      stroke: #1768cc;
    }

    &:hover {
      border: 1px solid rgba(9, 0, 63, 50%);

      & > div:first-child {
        box-shadow: none;
        transform: none;
      }
    }

    h4 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.5rem;
      font-weight: 700;
      font-family: 'GT Haptik', sans-serif;
      line-height: 1.15;
      color: var(--button-color);

      @media (max-width: variables.$device-sm) {
        font-size: 1.125rem;
      }
    }

    p {
      @include variables.paragraph(true);
      width: 100%;
      margin-top: -0.125rem;
      margin-right: 2rem;
      padding-left: 0.1rem;

      @media (max-width: variables.$device-sm) {
        margin-top: 0;
        font-size: 0.75rem;
      }
    }
  }

  &--smaller {
    @include utils.increase-specificity {
      padding: 0.75rem 1rem;

      h4 {
        font-size: 1.5rem;

        @media (max-width: variables.$device-sm) {
          font-size: 1rem;
        }
      }

      p {
        @include variables.paragraph;

        @media (max-width: variables.$device-sm) {
          font-size: 0.65rem;
        }
      }
    }
  }
}
