@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'libs/styles/components';

.section {
  @include layout.max-width;
  padding: 3.5rem 1.5rem 0;
  transform: scale(0.95) translateY(10px);
  opacity: 0.25;
  transition: variables.$transition-m;

  &:hover,
  &[data-in-view='true'] {
    transform: none;
    opacity: 1;
  }

  @media (max-width: variables.$device-sm) {
    padding: 1rem 1rem 0;
  }
}

.box {
  @include layout.column;
  position: relative;
  align-items: flex-start;
  margin: 1rem;
  border-radius: 2rem;
  padding: 2.5rem 3rem 3rem;
  overflow: hidden;
  background: rgba(variables.$color-disabled, 0.5);
  box-shadow: variables.$box-shadow-light;
  transition: variables.$transition-m;

  &:hover {
    background: variables.$color-disabled;
  }

  @media (max-width: variables.$device-sm) {
    margin: 0;
    border-radius: 1.5rem;
    padding: 1rem;

    & > h3,
    & > h4 {
      padding-left: 0.25rem;
    }

    & > :nth-child(6) {
      gap: 0.75rem;
      justify-content: center;
      width: 100%;

      > * {
        height: fit-content;
        padding: 0.5rem 1rem;
      }
    }
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &::after {
    @include layout.absolute-box;
    top: 1px;
    left: 1px;
    z-index: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: calc(2rem - 1px);
    background: linear-gradient(variables.$color-near-white, #fff);

    @media (max-width: variables.$device-sm) {
      border-radius: calc(1.5rem - 1px);
    }
  }

  & > h3 {
    @include variables.title(1, true);
    margin-top: 1rem;
    font-family: 'GT Haptik', sans-serif;

    b {
      color: variables.$color-org-2;
    }
  }

  & > h4 {
    color: variables.$color-disabled;
  }
}

.corner {
  position: absolute;
  top: 0;
  right: 0;
  transform-origin: top right;
  transition: variables.$transition-m;

  .box:hover & {
    transform: scale(1.1);
  }

  @media (max-width: variables.$device-sm) {
    display: none;
  }

  img {
    &:first-child {
      transform: translate(0, -3.5rem);
    }

    &:last-child {
      transform: translate(4.5rem, -11.5rem);
    }
  }
}

.title {
  @include components.for-link(variables.$color-org-2);
}

.items {
  @include layout.row(1rem);
  margin: 0.75rem 0 1.5rem;

  @media (max-width: variables.$device-sm) {
    @include layout.column(1rem);
  }

  & > div {
    @include layout.column(0.5rem);
    flex: 33%;
    align-items: flex-start;

    & > div {
      @include layout.row(0.5rem);
      align-items: center;
    }

    @media (max-width: variables.$device-sm) {
      gap: 0;
    }
  }

  h6 {
    @include variables.title(4);
    width: 90%;
    font-weight: 700;
    font-family: 'GT Haptik', sans-serif;
    line-height: 1;
  }

  svg {
    color: variables.$color-org-2;

    @media (max-width: variables.$device-sm) {
      width: 3rem;
    }
  }

  p {
    @include variables.paragraph(true);
    padding-left: 0.5rem;
    color: variables.$color-disabled;
  }
}

.dots {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1;
  max-width: 48%;
  height: auto;
  border-radius: calc(2rem - 1px);
  transform: scale(2);
  transform-origin: bottom right;
  mix-blend-mode: multiply;

  @media (max-width: variables.$device-sm) {
    border-radius: 1rem;
  }

  circle {
    fill: variables.$color-slate;
    transition: variables.$transition-m;

    .box:hover & {
      fill: variables.$color-gray;
    }
  }
}
