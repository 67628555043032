@use 'libs/styles/variables';

.checkbox {
  display: inline-flex;
  flex-direction: row;
  gap: 0.6rem;
  max-width: fit-content;

  &--disabled {
    opacity: 0.3;
  }

  &-wrapper > div {
    justify-content: flex-start;
    align-items: center;
    border: none;
    border-radius: 0;
    overflow: visible;

    @media (max-width: variables.$device-md) {
      min-height: unset;
    }
  }

  &-box {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 1.125rem;
    height: 1.125rem;
    border: 1.5px solid rgba(variables.$color-purple, 25%);
    border-radius: 0.25rem;
    padding: 0.1rem;
    background: variables.$color-near-white;
    cursor: pointer;

    &-checkmark {
      width: 0%;
      height: 0%;
      border-radius: 0.125rem;
      background: variables.$color-purple;
      opacity: 0;
      transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

      &--current-color {
        background: currentcolor;
      }

      &--checked {
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
  }

  & > label {
    font-size: 0.875rem;
    line-height: 112.5%;
    color: variables.$color-dark-light;
  }
}
