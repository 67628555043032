@use 'libs/styles/variables';

.input {
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }

  &--plain .input-container {
    border: none;
    border-radius: 0;

    .elements {
      border: none;
      border-radius: 0;

      &--right {
        padding-right: 0;
      }
    }
  }
}

.select {
  appearance: none;
}

.input-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid variables.$color-base;
  border-radius: variables.$border-radius-m;
  overflow: hidden;
  background: white;

  input,
  textarea,
  select {
    @include variables.header(4);
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border: none;
    padding: 16px 18px;
    background: none;
    outline: none;

    &::placeholder {
      color: variables.$color-slate;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    @media (max-width: variables.$device-md) {
      padding: 8px 16px;
    }
  }

  input {
    height: 56px;

    @media (max-width: variables.$device-md) {
      height: 36px;
    }
  }

  &:has(select) {
    position: relative;
    color: variables.$color-dark;
    cursor: pointer;

    &::after {
      position: absolute;
      top: 1.25rem;
      right: 1rem;
      width: 0.75rem;
      height: 0.75rem;
      border-top: 2px solid variables.$color-slate;
      border-right: 2px solid variables.$color-slate;
      transform: rotate(135deg);
      content: '';
      pointer-events: none;

      @media (max-width: variables.$device-md) {
        top: 1rem;
        right: 1rem;
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }

  @media (max-width: variables.$device-md) {
    min-height: 48px;
    border-radius: 12px;
  }
}

.switch-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 0;
  padding: variables.$space-s variables.$space-m;
  overflow: hidden;

  .label-container {
    padding: 0;
  }

  .extra-container {
    border: 0;
    padding: 0;
  }
}

.switch-label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  span {
    position: absolute;
    inset: 0;
    border-radius: 34px;
    background-color: #ccc;
    cursor: pointer;
    transition: 0.4s;
  }

  span::before {
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: white;
    transition: 0.4s;
    content: '';
  }

  input:checked + span {
    background-color: variables.$color-org;
  }

  input:focus + span {
    box-shadow: 0 0 1px variables.$color-light;
  }

  input:checked + span::before {
    transform: translateX(26px);
  }
}

.label-container {
  @include variables.paragraph;
  display: flex;
  justify-content: space-between;
  padding: variables.$space-s variables.$space-m variables.$space-s variables.$space-m;
  color: variables.$color-disabled;
}

.elements {
  display: flex;
  gap: variables.$space-s;
  justify-content: center;
  align-items: center;
  background: none;

  &--left {
    @include variables.header(4);
    padding-left: variables.$space-m;
    color: variables.$color-slate;
  }

  &--right {
    padding-right: variables.$space-m;
  }
}

.error {
  @include variables.label;
  justify-content: flex-start;
  padding: variables.$space-s variables.$space-m;
  padding-bottom: 0;
  color: variables.$color-error;

  &--right {
    @media (min-width: variables.$device-md) {
      justify-content: flex-end;
      text-align: right;
    }
  }
}
