@use 'libs/styles/variables';
@use 'libs/styles/layout';

@mixin category-card {
  @include layout.column;
  @include category-colors;
  position: relative;
  align-items: flex-start;
  border: 1px solid var(--card-color-quarter);
  overflow: hidden;
  color: var(--card-color);
  background: white;
  box-shadow: var(--shadow-color);
  cursor: pointer;
  transition: variables.$transition-s;

  &::after {
    position: absolute;
    inset: 0;
    z-index: 0;
    background: var(--card-color);
    opacity: 0.1;
    content: '';
  }

  &:hover {
    border-color: var(--card-color-half);
    box-shadow: 0 18px 26px 0 var(--card-color-eight);
    transform: translateY(-4px);

    .card-dots-background circle {
      fill: var(--card-color);
    }
  }

  &-button {
    border-color: var(--card-color);
    background-color: var(--card-color);

    &:hover {
      border-color: var(--card-color);
      filter: brightness(1.25);
    }
  }

  h3 {
    @include variables.title(4);
    margin-top: 0;
    font-weight: 800;
    font-family: 'GT Haptik', sans-serif;
    color: var(--card-color);
  }

  p {
    margin-bottom: auto;
    padding-bottom: 1rem;
    text-align: left;
  }

  & > * {
    z-index: 2;
  }

  & > .card-dots-background {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 0;

    circle {
      transition: variables.$transition-s;
      fill: var(--card-color-half);
    }
  }
}

@mixin gen-colors($color) {
  --card-color: #{$color};
  --card-color-half: #{rgba($color, 0.5)};
  --card-color-quarter: #{rgba($color, 0.25)};
  --card-color-tenth: #{rgba($color, 0.1)};
  --card-color-eight: #{rgba($color, 0.125)};
  --shadow-color: 0px 18px 26px 0px var(--card-color-tenth);
}

@mixin category-colors {
  // Ethereum, Goerli, Sepolia, Foundry

  &--chain-1,
  &--chain-5,
  &--chain-11155111,
  &--chain-31337 {
    @include gen-colors(variables.$color-ethereum);
  }

  // Optimism, Optimism Goerli

  &--chain-10,
  &--chain-420 {
    @include gen-colors(variables.$color-optimism);
  }

  // Polygon, Polygon Mumbai

  &--chain-137,
  &--chain-80001 {
    @include gen-colors(variables.$color-polygon);
  }

  // Base, Base Goerli, Base Sepolia, Foundry 2

  &--chain-8453,
  &--chain-84531,
  &--chain-84532,
  &--chain-31338 {
    @include gen-colors(variables.$color-basechain);
  }

  // Arbitrum One, Arbitrum Goerli

  &--chain-42161,
  &--chain-421613 {
    @include gen-colors(variables.$color-arbitrum);
  }
}
