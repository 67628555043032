@use 'libs/styles/variables';
@use 'libs/styles/layout';

.testimonial-carousel {
  @include layout.max-width;
  align-self: center;

  @media (max-width: variables.$device-sm) {
    padding: 0;
  }

  & > div {
    @include variables.side-fade-gradients($width: 120px, $offset: 50%);
    padding: 0 60px 60px 90px;

    @media (max-width: variables.$device-sm) {
      @include variables.side-fade-gradients($width: 120px, $offset: 50%);
      padding: 0 16px 60px;
    }
  }
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 525px;
  height: 320px;
  padding-top: 35px;
  overflow: hidden;

  p {
    @include variables.paragraph($big: true);
    display: -webkit-box;
    margin-bottom: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: variables.$color-gray;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }

  a {
    @include variables.text-link;
    color: variables.$color-purple;
  }

  b,
  strong {
    font-weight: 500;
  }
}

.pull-quote {
  margin-top: 5px;

  p {
    font-size: 1.2rem;
    color: variables.$color-disabled;
  }
}

.testimonial-logo {
  margin-bottom: 20px;

  img,
  svg {
    max-width: 100%;
    max-height: 50px;
    object-fit: contain;
    object-position: top left;
  }
}

.testimonial-from {
  @include variables.paragraph;
  margin-top: auto;

  // padding-top: 16px;
  margin-bottom: 5px;

  // border-top: 1px solid variables.$color-lighter;
  color: variables.$color-purple-gray;
}
