@use 'libs/styles/variables';

.section-label {
  @include variables.header(1);
  width: fit-content;
  margin-bottom: 32px;
  border-bottom: 1px solid variables.$color-purple;
  padding-bottom: 12px;
  font-weight: 700;
  font-family: 'GT Haptik', sans-serif;
  letter-spacing: -0.04rem;
  text-align: center;
  color: variables.$color-dark-light;

  &.smaller {
    @include variables.title(4);
  }
}
