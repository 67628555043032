@use 'libs/styles/variables';

.next-button {
  margin: 0 auto;
  border: 1px solid variables.$color-lighter;
  padding: 0.25rem 1rem;
  background: variables.$color-white;

  &:hover {
    border-color: rgba(variables.$color-purple, 0.3);
  }

  &:disabled {
    box-shadow: none;

    &:hover {
      transform: none;
    }
  }

  &.inline {
    display: inline-flex;
    margin: 0;
  }
}
