@use 'libs/styles/layout';
@use 'libs/styles/variables';
@use 'libs/styles/components';

.for-nonprofits-feature-section {
  @include variables.rainbow-gradient-background('bottom', true, 300px);
  margin-top: 4rem;
  background-position: top;

  &::after {
    top: -300px;
    bottom: unset;
    background: linear-gradient(180deg, transparent 0%, white 100%),
      linear-gradient(
        270deg,
        rgba(173, 122, 255, 13%) 6.24%,
        rgba(207, 117, 203, 13%) 47.74%,
        rgba(252, 113, 138, 13%) 65.24%,
        rgba(248, 126, 38, 13%) 88.24%,
        rgba(252, 137, 53, 13%) 100.24%
      );
  }

  &::before {
    @include layout.absolute-box;
    height: 1px;
    background: linear-gradient(270deg, #53acde 0%, #a499ff 43.33%, #ff7f7f 56.76%, #ffaa6b 92.16%, #ffbc58 100%);
  }

  &__inner {
    @include layout.max-width;
    @include layout.column(0.25rem);
    padding: 0 4rem;
    padding-top: 3.5rem;

    @media (max-width: variables.$device-sm) {
      padding: 0 1rem;
      padding-top: 2rem;
    }

    > h2 {
      @include variables.title(1, true);
      margin-top: 1.5rem;
      font-family: 'GT Haptik', sans-serif;

      b {
        color: variables.$color-org;
      }
    }

    > h4 {
      margin: 0;
      color: variables.$color-disabled;
    }
  }

  &__title {
    @include components.for-link(variables.$color-org);
    width: fit-content;
    margin-left: -0.25rem;
  }

  &__grid {
    margin: 1.5rem 0;

    @media (max-width: variables.$device-sm) {
      @include layout.column(1rem);
    }

    > * {
      flex: 33%;
    }

    h6 {
      @include variables.title(4);
      width: 75%;
      font-weight: 700;
      font-family: 'GT Haptik', sans-serif;
      line-height: 1;
    }

    p {
      @include variables.paragraph(true);
      color: variables.$color-disabled;

      a {
        text-decoration: underline;
        color: variables.$color-org;
      }
    }
  }

  &__actions {
    @include layout.row(0.75rem);
    position: relative;
    justify-content: center;
    margin-top: 1rem;

    > a {
      position: relative;
    }

    &::before {
      @include layout.absolute-box;
      top: 50%;
      z-index: 0;
      height: 1px;
      background: rgba(variables.$color-org, 0.25);
    }
  }

  &__lookup {
    margin-top: 3rem;

    @media (max-width: variables.$device-sm) {
      @include layout.column(1rem);
    }

    > * {
      flex: 50%;

      &:first-child {
        border-right: 1px solid variables.$color-lighter;
        padding-right: 1rem;

        @media (max-width: variables.$device-sm) {
          border-right: none;
          padding-right: 0;
        }

        h5 {
          @include variables.title(4);
          margin: 0;
          font-weight: 700;
          font-family: 'GT Haptik', sans-serif;
        }

        p {
          color: variables.$color-org;
        }
      }
    }

    label {
      @include variables.label;
      padding-left: 0.5rem;
    }
  }

  &__disclaimer {
    @include variables.label;
    max-width: 30rem;
    margin-top: 0.5rem;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }
}
