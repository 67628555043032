@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'libs/styles/components';

.section {
  @include layout.max-width;
  padding: 3.5rem 1.5rem 0;
  transform: scale(0.95) translateY(10px);
  opacity: 0.25;
  transition: variables.$transition-m;

  &:hover,
  &[data-in-view='true'] {
    transform: none;
    opacity: 1;
  }

  @media (max-width: variables.$device-sm) {
    padding: 1rem 1rem 0;
  }
}

.box {
  position: relative;
  margin: 0 1rem;
  border-radius: 2rem;
  padding: 2.5rem 3rem 3rem;
  overflow: hidden;
  background: rgba(variables.$color-org-2, 0.5);
  box-shadow: variables.$box-shadow-light;
  transition: variables.$transition-m;

  &:hover {
    background: variables.$color-org-2;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  @media (max-width: variables.$device-sm) {
    margin: 0;
    border-radius: 1.5rem;
    padding: 1rem 1rem 1.5rem;
  }

  &::after {
    @include layout.absolute-box;
    top: 1px;
    left: 1px;
    z-index: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: calc(2rem - 1px);
    background: linear-gradient(180deg, #f7f6ff 0%, #fff 20.48%);

    @media (max-width: variables.$device-sm) {
      border-radius: calc(1.5rem - 1px);
    }
  }
}

.grid {
  @include layout.multi-column(2rem, 3);
  align-content: stretch;

  @media (max-width: variables.$device-sm) {
    @include layout.column(1rem);
  }
}

.header {
  @include layout.row;
  align-items: stretch;
  margin-bottom: 2rem;

  @media (max-width: variables.$device-sm) {
    margin-bottom: 1rem;
  }

  img {
    width: auto;
    height: 14.5rem;
    opacity: 0.5;
    transition: variables.$transition-m;

    .box:hover & {
      opacity: 1;
    }

    @media (max-width: variables.$device-md) {
      height: 10rem;
    }
  }

  div {
    flex: 0 0 34rem;
    width: 34rem;
    max-width: 100%;
    padding-top: 0.25rem;
    text-align: center;

    @media (max-width: variables.$device-sm) {
      padding: 0.5rem;
    }

    &,
    h4 {
      color: variables.$color-org-2;
    }

    h2 {
      @include variables.title(1, true);
      margin-block: 0.25rem;
      font-family: 'GT Haptik', sans-serif;
      color: variables.$color-dark-light;
    }

    svg {
      opacity: 0.5;
    }
  }
}

.spread {
  @include layout.row(0);
  margin-top: 3rem;
  border-top: 1px solid rgba(variables.$color-org-2, 0.25);
  padding-top: 2rem;

  @media (max-width: variables.$device-md) {
    @include layout.column(1rem);
    margin-top: 1rem;
    padding-top: 0;
  }

  img {
    pointer-events: none;
    filter: none;

    @media (max-width: variables.$device-sm) {
      display: none;
    }
  }

  & > div {
    &:last-child {
      flex: 0 0 35%;
      padding: 1.5rem;

      @media (max-width: variables.$device-md) {
        padding: 1rem 0.5rem 0;
      }

      h3 {
        @include variables.title(2, true);
        margin-bottom: 1rem;
        font-family: 'GT Haptik', sans-serif;
        line-height: 0.95;
      }

      p {
        @include variables.paragraph(true);
        color: variables.$color-org-2;
      }
    }
  }
}

.dots {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1;
  max-width: 48%;
  height: auto;
  border-radius: calc(2rem - 1px);
  transform: scale(2);
  transform-origin: bottom right;

  @media (max-width: variables.$device-sm) {
    border-radius: 1rem;
  }

  circle {
    fill: rgba(variables.$color-org-2, 0.5);
    transition: variables.$transition-m;

    .box:hover & {
      fill: variables.$color-org-2;
    }
  }
}
